import Image from 'next/future/image';
import React, { useEffect, useRef, useState } from 'react';

import style from './styles.module.css';

const Embed = (props: any) => {
  const playIcon = '/images/icons/play-icon.svg';
  const [imageClicked, setImageClicked] = useState(false);
  const ref = useRef(null);

  const onThumbnailClick = () => {
    setImageClicked(true);
  };

  useEffect(() => {
    const playVideo = ref.current;
    if (playVideo) {
      playVideo.addEventListener('click', onThumbnailClick, { once: true });
    }
    return () =>
      playVideo && playVideo.removeEventListener('click', onThumbnailClick);
  });
  return (
    <div className={style.container}>
      <div className={style.video}>
        {!imageClicked ? (
          <>
            <Image
              src={props?.imageUrl}
              alt={props?.alternativeText}
              width="600"
              height="375"
              loading="lazy"
              className="img-fluid thumbnail"
              sizes="(max-width: 375px) 350px, (max-width: 576px) 550px, (max-width: 768px) 600px, (max-width: 992px) 600px, (max-width: 1280px) 600px"
            />
            <img
              id="play-button"
              ref={ref}
              className="img-fluid play-btn"
              src={playIcon}
              alt="play button"
            />
          </>
        ) : (
          <iframe
            src={`${props?.url}?autoplay=1`}
            width="775"
            height="435"
            allow="autoplay"
          />
        )}
      </div>
    </div>
  );
};
export default Embed;
